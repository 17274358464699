import React, { useState } from "react";

import { Button, Label,  Modal,  ModalBody, ModalHeader } from "reactstrap";

import { Col } from 'reactstrap';

import * as Yup from "yup";
import { useFormik } from "formik";
import { FormikProvider, Field } from 'formik';

import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import { GalliumApiErrorResponse, VirtualMachineDetail, HostVolume, ReconfigureVmRequest, ConfigureVmDiskAction, AvailableIso } from "generated";
import { useGetHostVolumes } from "GalliumAPIHooks/HostVolume/HostVolumeHooks";
import { useTranslation } from "react-i18next";
import Loader from "Components/Gallium/Loader";
import ErrorNotice from "Components/Gallium/ErrorNotice";
import { useModifyVirtualMachineConfig } from "GalliumAPIHooks/VirtualMachine/VirtualMachineHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { byPrefixAndName } from "@awesome.me/kit-d2e55409b9/icons";

interface AttachOpticalDiskModalFormProps {
    closeModal: () => void,
    candidateVolumes: Array<AvailableIso>,
    virtualMachine: VirtualMachineDetail
}

const AttachOpticalDiskModalForm:React.FC<AttachOpticalDiskModalFormProps> =({candidateVolumes, virtualMachine, closeModal}) => {
    const {t} = useTranslation()   
    const { trigger, isMutating } = useModifyVirtualMachineConfig(virtualMachine.slug);

    const [errorObject, setErrorObject] = useState(null);

    const handleAttachDiskChangeFail = (error: GalliumApiErrorResponse) => {
        diskAttachFormik.setErrors(error.errors || {})
        setErrorObject(error || {})
    }

    const handleAttachDiskChangeSuccess = () => {
        diskAttachFormik.resetForm()
        closeModal()
    }

    const handleAttachDiskRequest = (values: ReconfigureVmRequest) => {
        const options = {
            onError(error: object) {
                handleAttachDiskChangeFail(error)
            },
            onSuccess() {
                handleAttachDiskChangeSuccess()
            }
        }
        setErrorObject(undefined)
        trigger(values, options);
    }

    const diskAttachFormik = useFormik({
        enableReinitialize: true,

        initialValues: {
            disks: [
                {
                    diskSlug: "",
                    action: ConfigureVmDiskAction.ATTACH
                }
            ]
        },
        validationSchema: Yup.object().shape({

        }),
        onSubmit: (values) => {
            handleAttachDiskRequest(values);
        }
    });

    return (
        <React.Fragment>
            <ModalBody className="py-3 px-5 mb-3">
                <ErrorAlert errorObj={errorObject}/>
                <FormikProvider value={diskAttachFormik}>

                    <Label>{t('virtualMachine.labels.selectISO')}</Label>
                    <Field
                        id="diskAttachField"
                        name={`disks[${0}].diskSlug`}
                        as="select"
                        className="form-select mb-3"
                        
                    >
                        <option>Please Select Disk</option>
                        {candidateVolumes?.map((volume, key) => (
                            <option value={volume.volumeSlug} key={key}>{volume.filename}</option>
                        ))
                        }
                    </Field>

                

                    <Col lg={12} className="text-end mt-3">
                        <GalliumSubmitButton formik={diskAttachFormik} color="success" spinner={isMutating}>
                            {t('virtualMachine.buttons.opticalAttach')}
                        </GalliumSubmitButton>
                    </Col>

                </FormikProvider>
            </ModalBody>
        </React.Fragment>
    )
}

interface AttachOpticalDiskModalProps {
    showModal: boolean,
    closeModal: () => void,
    virtualMachine: VirtualMachineDetail
}

const AttachOpticalDiskModal: React.FC<AttachOpticalDiskModalProps> = ({ showModal, closeModal, virtualMachine }) => {
    const {t} = useTranslation()
    const {data, error, isLoading} = useGetHostVolumes(virtualMachine.host.slug)
    const unattachedVolumes = data?.availableIsos || [];

    return (    
        <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
            <ModalHeader className="p-3 bg-light" toggle={()=>{closeModal()}}>
                {t('virtualMachine.buttons.opticalAttach')}
            </ModalHeader>      
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                
                unattachedVolumes.length !== 0 ? (
                    
                    <AttachOpticalDiskModalForm candidateVolumes={unattachedVolumes} virtualMachine={virtualMachine} closeModal={closeModal}/>

                ):(
                    <ModalBody className="p-5 m-5 text-center">
                        <Button disabled outline color="secondary">
                            <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-slash"]} className='me-2'/>
                            {t('virtualMachine:diskAttachNoCandidate')}
                        </Button>
                    </ModalBody>
                )
            )}
        </Modal>
    );
};

export default AttachOpticalDiskModal


