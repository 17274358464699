import { useChangeVmPowerState } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import { ApiVirtualMachine, ApiVmStatus, VirtualMachineDetail, VmPowerStateTransition } from 'generated';
import React from 'react';
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

import { GalliumSpinnerButton } from '../GalliumForms';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import useAsyncCommand from 'Components/Hooks/useAsyncCommand';


interface VMListControlsProps {
    vm: ApiVirtualMachine | VirtualMachineDetail;
}
const VMListControls: React.FC<VMListControlsProps> = ({vm}) => {

    const {trigger} = useChangeVmPowerState(vm.slug);

    const handleVirtualMachinePowerStateRequest = (values: VmPowerStateTransition) => {
        startCommand(trigger, values);
    }

    const { isUpdating, startCommand } = useAsyncCommand({
        onError: (error) => {
            toast.error(error?.error || "Power State Transition Failed")
        }
    });

    const renderPowerControls = () => {
        switch (vm.status) {
            case ApiVmStatus.RUNNING:
                return (
                    <React.Fragment>
                        <Button id="restartBtn" color="success" aria-label="Restart" title="Restart" 
                            className="btn btn-icon btn-soft-success" 
                            onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.SHUTDOWN)}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal["arrows-rotate"]} className='fa-fw'/> 
                        </Button>
                        <Button color="danger" aria-label="Shutdown" title="Shutdown" 
                            className="btn btn-icon btn-soft-danger" 
                            onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.SHUTDOWN)}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal["stop"]} className='fa-fw'/> 
                        </Button>
                        <UncontrolledButtonDropdown id="btnGroupVerticalDrop1">
                            <DropdownToggle color="light" className='btn-icon dropdown-toggle'>
                                <FontAwesomeIcon icon={byPrefixAndName.fal["chevron-down"]} className='fa-fw'/> 
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.HALT)}>
                                    <FontAwesomeIcon icon={byPrefixAndName.fal["triangle-exclamation"]} className='me-1 fa-fw'/>  
                                    Stop 
                                </DropdownItem>
                                <DropdownItem onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.RESET)}>
                                    <FontAwesomeIcon icon={byPrefixAndName.fal["rotate-exclamation"]} className='me-1 fa-fw'/> 
                                    Reset 
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </React.Fragment>
                );
            case ApiVmStatus.STOPPED:
                return (
                    <Button color="soft-success" className="btn-icon" 
                        onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.START)}>
                        <FontAwesomeIcon icon={byPrefixAndName.fal["play"]} className=''/> 
                    </Button>
                );
            case ApiVmStatus.SUSPENDED:
                return (
                    <React.Fragment>
                        <Button color="soft-success" className="btn-icon" title="Resume" 
                            onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.START)}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal["play-pause"]} className=''/> 
                        </Button>
                        <Button color="soft-danger" className="btn-icon" title="Stop" 
                            onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.HALT)}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal["stop"]} className=''/> 
                        </Button>
                    </React.Fragment>
                );
            case ApiVmStatus.PAUSED:
                return (
                    <React.Fragment>
                        <Button color="soft-success" className="btn-icon" title="Resume" 
                            onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.START)}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal["play-pause"]} className=''/> 
                        </Button>
                        <Button color="soft-danger" className="btn-icon" title="Stop" 
                            onClick={() => handleVirtualMachinePowerStateRequest(VmPowerStateTransition.HALT)}>
                            <FontAwesomeIcon icon={byPrefixAndName.fal["stop"]} className=''/> 
                        </Button>
                    </React.Fragment>
                );
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {!isUpdating ? (
                <ButtonGroup>
                    {vm.availableActions.changePowerState ? (
                        renderPowerControls()
                    ) : (
                        <Button className="btn btn-icon btn-outline-secondary" aria-label="Hypervisor Offline" disabled>
                            <FontAwesomeIcon icon={byPrefixAndName.fak["light-server-circle-arrow-down"]} className='fa-fw'/>
                        </Button>
                    )}
                </ButtonGroup>
            ) : (
                <GalliumSpinnerButton color='soft-primary'/>
            )}
        </React.Fragment>
    );
}

export default VMListControls;