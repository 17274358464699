import ErrorAlert from "Components/Gallium/ErrorHelper";
import { GalliumSubmitButton } from "Components/Gallium/GalliumForms";
import React, { SetStateAction, useState } from "react";
import { Button, Col, Modal, ModalBody, Row, Tooltip } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons'
import { GalliumApiErrorResponse, ReconfigureVmRequest, VirtualMachineDetail, VmDisk } from "generated";
import { useTranslation } from "react-i18next";
import { useModifyVirtualMachineConfig } from "GalliumAPIHooks/VirtualMachine/VirtualMachineHooks";
import { FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";

interface MakeBootModalProps {
    showModal: boolean,
    setShowModal: React.Dispatch<SetStateAction<boolean>>,
    disk: VmDisk,
    virtualMachine: VirtualMachineDetail,
}

const MakeBootModal: React.FC<MakeBootModalProps> = ({ showModal, setShowModal, disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const closeModal = () => {
        expandDiskFormik.resetForm()
        setShowModal(false)
    }

    const { trigger, isMutating } = useModifyVirtualMachineConfig(virtualMachine.slug);

    const [errorObject, setErrorObject] = useState<GalliumApiErrorResponse | null>(null);

    const handleMakeBootDiskFail = (error: GalliumApiErrorResponse) => {
        setErrorObject(error || {})
    }

    const handleMakeBootDiskSuccess = () => {
        toast.info(t("virtualMachine.toasts.modifyConfigSubmitted"))
        closeModal()
    }

    const handleMakeBootDiskRequest = (requestObj: ReconfigureVmRequest) => {
        const options = {
            onError(error: object) {
                handleMakeBootDiskFail(error)
            },
            onSuccess() {
                handleMakeBootDiskSuccess()
            }
        }
        setErrorObject(undefined)
        
        trigger(requestObj, options);
    }

    const expandDiskFormik = useFormik({
        initialValues: {
            "assignBootDevice": { "volumeSlug": disk.volumeSlug}
        },
        onSubmit: (values) => {
            handleMakeBootDiskRequest(values)
        },
    });

    return (
        <FormikProvider value={expandDiskFormik}>
            <Modal isOpen={showModal} toggle={closeModal} centered={true}>  
                <ModalBody className="py-3 px-5 text-center">
                    <ErrorAlert errorObj={errorObject}/>
                    <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-play"]} className='mt-3 fs-36 text-success'/>
                    <div className="mt-3 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>{t('translation:virtualMachine:diskMakeBootTitle')}</h4>
                        <p className="text-muted mx-4 mb-0">
                            {t('translation:virtualMachine:diskMakeBootDescription')} 
                        </p>
                    </div>
                    <Row className="g-2 mt-2">
                        <Col lg={12} className="justify-content-center mt-3">
                            
                            <GalliumSubmitButton formik={expandDiskFormik} color="success" spinner={isMutating}>
                                {t('translation:virtualMachine:diskMakeBoot')}
                            </GalliumSubmitButton>
                            <Button
                                className="btn w-sm btn-light ms-2"
                                data-bs-dismiss="modal"
                                onClick={closeModal}
                            >
                                {t('common.cancel')}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
                
                
            </Modal>
        </FormikProvider>
    );
};

interface DiskMakeBootButtonProps {
    disk: VmDisk
    virtualMachine: VirtualMachineDetail
}

const DiskMakeBootButton: React.FC<DiskMakeBootButtonProps> = ({ disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    const [isBtntooltipOpen, setBtnTooltipOpen] = React.useState(false);

    return (
        <React.Fragment>
            <MakeBootModal
                showModal={showModal}
                setShowModal={setShowModal}
                disk={disk}
                virtualMachine={virtualMachine}
            />
            {virtualMachine.availableActions.resizeDisk ? (
                <Button color="soft-primary" id={`expandDiskButton-${disk.volumeSlug}`} className='mt-1 btn-sm btn-block' onClick={() => setShowModal(true)}>
                    <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-play"]} className='me-1'/>
                    {t('translation:virtualMachine:diskMakeBoot')}
                </Button>
            ):(
                <React.Fragment>
                    <Button color="soft-secondary" id={`expandDiskButton-${disk.volumeSlug}`} className='mt-1 btn-sm btn-block'>
                        <FontAwesomeIcon icon={byPrefixAndName.fak["light-hard-drive-circle-play"]} className='me-1'/>
                        {t('translation:virtualMachine:diskMakeBoot')}
                    </Button>
                    
                    <Tooltip
                        isOpen={isBtntooltipOpen}
                        placement="bottom"
                        target={`expandDiskButton-${disk.volumeSlug}`}
                        toggle={() => { setBtnTooltipOpen(!isBtntooltipOpen) }}>
                        <div>{t('translation:common:warningActionUnavailable')}</div>
                    </Tooltip>
                </React.Fragment>
            )}
        </React.Fragment>
    )
};

export default DiskMakeBootButton;