import React from 'react';

import HypervisorActionPower from './ActionPower';
import HypervisorActionArchive from './ActionArchive';
import { HostDetail } from 'generated';
import GalliumPageColumn from 'Components/Gallium/GalliumPageColumn';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

type HypervisorActionsProps = {
  hypervisor: HostDetail;
};

const HypervisorActions: React.FC<HypervisorActionsProps> = ({hypervisor}) => {
    const {t} = useTranslation()
    return (

        <GalliumPageColumn>
            
            <HypervisorActionPower hypervisor={hypervisor} />

            <HypervisorActionArchive hypervisor={hypervisor} />

            <Card className='mb-2'>
                <CardHeader >
                    <h4 className="card-title mb-0">{t("hypervisor.actions.fileAction")}</h4>
                </CardHeader>
                <CardBody >
                    <Row>
                        <Col md={8}>
                            <h6 className="mb-0">{t("hypervisor.actions.connectTransferManager")}</h6>
                            <p className="text-muted mb-0">{t("hypervisor.actions.connectTransferManagerDetail")}</p>
                        </Col>
                        <Col md={4} className='text-end'>
                            <Link to="ftm" className=" btn btn-primary">{t("hypervisor.actions.connectTransferManagerBtn")}</Link>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </GalliumPageColumn>
    )
};

export default HypervisorActions;