import React from 'react';
import { Link, useParams } from 'react-router-dom';

import {
    Card,
    CardBody,
    Container,
    Progress,
} from "reactstrap"

import GalliumBreadcrumb from 'Components/Gallium/GalliumBreadcrumb';
import Loader from 'Components/Gallium/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import { GalliumFormHeader } from 'Components/Gallium/GalliumForms';
import moment from 'moment';
import { HostVolumeHistoryEvent, HostVolumeHistoryEventType } from 'generated';
import { useGetHostVolumeDetails } from 'GalliumAPIHooks/HostVolume/HostVolumeHooks';
import { useTranslation } from 'react-i18next';
import TranslationBadge from 'Components/Gallium/TranslationBadge';
import { formatDiskSize } from 'Components/Gallium/FormatDiskSize';

const HypervisorVolumeDetail = () => {
    const {t} = useTranslation()
    const crumbs = [
        {
            name: "Virtual Disk",
        },
        {
            name: "Virtual Disk Detail",
        }
    ]
    const hostSlug = useParams().hostSlug;
    const volumeSlug = useParams().volumeSlug;
    const {data, isLoading, error} = useGetHostVolumeDetails(hostSlug, volumeSlug)

    const detailedTime = (input): string => {
        const momentDate = moment.utc(input).local(); // Convert once, use multiple times
        return `${momentDate.fromNow()} at ${momentDate.format('h:mmA (UTCZ)')}`;
    };

    const DiskHistoryDot = ({event}:{event:HostVolumeHistoryEvent}) => {
        const {t} = useTranslation()
        return (
            <p className="text-truncate fs-14 mb-2">
                <i className={`mdi mdi-circle align-top text-primary me-2`}></i>
                {t(`HostVolumeHistoryEventType.${event.eventType}`)}
                {event.eventType === HostVolumeHistoryEventType.ATTACH ? (
                    ` to ${event.attachment.vmName}`
                ):(null)}
                {" - "}
                {detailedTime(event.timestamp as unknown)}
            </p>
        )
    }
    const sizeUsed = formatDiskSize(data?.sizeUsedMb)
    const sizeTotal = formatDiskSize(data?.sizeTotalMb)

    return (
        <div className="page-content">
            <Container fluid>
                {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                    <React.Fragment>
                        <GalliumBreadcrumb title="Virtual Disk Detail" crumbs={crumbs}>
                            
                        </GalliumBreadcrumb>
                        <Card>
                            <CardBody>
                            
                                <GalliumFormHeader icon="hard-drive" title={data.name}>
                                    <div className='mb-3 mt-3'>
                                        <h6>{t("globals.status")}</h6>
                                        <TranslationBadge parentKey="HostVolumeState" childKey={data.state} className='fs-6'/>
                                    </div>
                                    <div className='mb-3 mt-3'>
                                        <h6>Size</h6>
                                        <span className=" fw-semibold">{sizeTotal}</span><span className=" fw-semibold text-muted"> ({sizeUsed} {t("globals.used")})</span>
                                        <Progress multi className='progress-xl mt-0'>
                                            <Progress bar color="primary" value={data.sizeUsedMb} />
                                            <Progress bar color="secondary-subtle" value={data.sizeTotalMb - data.sizeUsedMb} />
                                        </Progress>
                                    </div>
                                </GalliumFormHeader>


                                <GalliumFormHeader icon="clock" title="History">
                                    {data.events.map((event,index)=>(
                                        <DiskHistoryDot key={index} event={event}/>
                                    ))}
                                </GalliumFormHeader>
                            </CardBody>
                        </Card>
                        <Link to={`/hypervisors/${hostSlug}`} className="btn btn-link text-decoration-none btn-label pt-2 mt-0">
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                            Back to Hypervisor
                        </Link> 
                    </React.Fragment>
                )}
            </Container>
        </div>
    );
};

export default HypervisorVolumeDetail;
                    