import { VmDiskType } from 'generated';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons'

import {
    Card,
    CardBody,
    Progress,
    Row,
} from "reactstrap"
import { formatDiskSize } from 'Components/Gallium/FormatDiskSize';
import AddDisk from './AddDisk';
import DiskDetachButton from './DetachDisk';
import DiskExpandButton from './ExpandDisk';
import { useGetVirtualMachine } from 'GalliumAPIHooks/VirtualMachine/VirtualMachineHooks';
import { useParams } from 'react-router-dom';
import GalliumThirdColumn from 'Components/Gallium/GalliumThirdColumn';
import Loader from 'Components/Common/Loader';
import ErrorNotice from 'Components/Gallium/ErrorNotice';
import DiskMakeBootButton from './MakeDiskBoot';

const VirtualMachineDisk  = ({ disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const diskAllocatedPercent = (disk.sizeUsedMb / disk.sizeTotalMb) * 100

    const diskAvailablePercent = ((disk.sizeTotalMb - disk.sizeUsedMb) / disk.totalMb) * 100

    const diskAllocatedString = formatDiskSize(disk.sizeUsedMb);
    const diskAvailableString = formatDiskSize(disk.sizeTotalMb - disk.sizeUsedMb);

    return (
        <Card key={disk.slug} className='mb-3'>
            <CardBody className='pb-0'>
                <Row className="gy-3">
                    <div className="col-sm">
                        <h5 className="fs-14 text-truncate">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["hard-drive"]} className='me-2'/>
                            {disk.boot ? t('translation:virtualMachine:diskTitleBoot') :  t('translation:virtualMachine:diskTitle')}
                        </h5>
                        <ul className="list-inline text-muted mb-0">
                            <li className="list-inline-item">
                                {t('translation:virtualMachine:diskPath') + ": "}
                                <span className="fw-medium">
                                    {disk.devicePath}
                                </span>
                            </li>
                        </ul>

                          
                    </div>
                    <div className="col-sm-auto">
                        <div className="d-flex align-items-center gap-2 text-muted">
                            <DiskExpandButton disk={disk} virtualMachine={virtualMachine} />
                            {disk.boot ? (
                                null
                            ):(
                                <>
                                    <DiskMakeBootButton disk={disk} virtualMachine={virtualMachine} />
                                    <DiskDetachButton disk={disk} virtualMachine={virtualMachine} />
                                </>
                            )}
                        </div>
                    </div>
                </Row>
            </CardBody>
            <CardBody className='pt-0'>
                <div className="mt-3 pt-0">
                    <Progress multi className='progress-xls mt-0'>
                        <Progress bar color="primary" value={diskAllocatedPercent} />
                        <Progress bar color="secondary-subtle" value={diskAvailablePercent} />
                    </Progress>
                  
                </div>

                <div className="mt-2 pt-2">
                    <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                            <p className="text-truncate text-muted fs-14 mb-0">
                                <i className="mdi mdi-circle align-middle text-primary me-2"></i>
                                {t('translation:virtualMachine:diskSpaceUsed')}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <p className="mb-0">{diskAllocatedString}</p>
                        </div>
                    </div>
                    <div className="d-flex mb-0">
                        <div className="flex-grow-1">
                            <p className="text-truncate text-muted fs-14 mb-0">
                                <i className="mdi mdi-circle align-middle text-secondary me-2"></i>
                                {t('translation:virtualMachine:diskSpaceFree')}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <p className="mb-0">{diskAvailableString}</p>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )

}

const VirtualMachineOpticalDisk  = ({ disk, virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    return (

        <Card className="mb-3" key={disk.slug}>
            <CardBody className='pb-0'>
                <Row className="gy-3">
                    <div className="col-sm">
                        <h5 className="fs-14 text-truncate">
                            <FontAwesomeIcon icon={byPrefixAndName.fal["compact-disc"]} className='me-2'/>
                            {t('translation:virtualMachine:opticalDiskTitle')}
                        </h5>
                        <ul className="list-inline text-muted mb-0">
                            <li className="list-inline-item">
                                {t('translation:virtualMachine:diskPath') + ": "}
                                <span className="fw-medium">
                                    {disk.devicePath}
                                </span>
                            </li>
                            {disk.isoFilename ? (
                                <li className="list-inline-item">
                                    {t('translation:virtualMachine:opticalDiskFile') + ": "}
                                    <span className="fw-medium">
                                        {disk.isoFilename}
                                    </span>
                                </li>
                            ):(null)}
                        </ul>

                          
                    </div>
                    <div className="col-sm-auto">
                        <div className="d-flex align-items-center gap-2 text-muted">
                            <DiskMakeBootButton disk={disk} virtualMachine={virtualMachine} />
                            <DiskDetachButton disk={disk} virtualMachine={virtualMachine} />
                        </div>
                    </div>
                </Row>
            </CardBody>
            <CardBody className='pt-0'>
                <div className="mt-2 pt-2">
                    <div className="d-flex mb-2">
                        <div className="flex-grow-1">
                            <p className="text-truncate text-muted fs-14 mb-0">
                                <i className="mdi mdi-circle align-middle text-primary me-2"></i>
                                {t('translation:virtualMachine:diskSpaceSize')}
                            </p>
                        </div>
                        <div className="flex-shrink-0">
                            <p className="mb-0">{formatDiskSize(disk.sizeTotalMb)}</p>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )

}


const VirtualMachineStorage = () => {
    const { t } = useTranslation()
    const {vmSlug} = useParams()
    const {data: virtualMachine, isLoading, error} = useGetVirtualMachine(vmSlug)

    return (
        <GalliumThirdColumn heading={t("virtualMachine.nav.STORAGE.title")} icon={t("virtualMachine.nav.STORAGE.icon")}>
            {isLoading ? (<Loader />) : error ? (<ErrorNotice />) : (
                <React.Fragment>
                    <AddDisk virtualMachine={virtualMachine} />
                    {virtualMachine?.disks?.map((disk, index) => 
                        disk.deviceType === VmDiskType.CDROM ? (
                            <VirtualMachineOpticalDisk disk={disk} virtualMachine={virtualMachine} key={index}/>
                        ) : (
                            <VirtualMachineDisk disk={disk} key={index} virtualMachine={virtualMachine} />
                        )
                    )}
                </React.Fragment>
            )}
        </GalliumThirdColumn>
    )
};

export default VirtualMachineStorage;