import React, { useState } from 'react';

import {
    Button,
    Tooltip,
} from "reactstrap"

import AttachDiskModal from './AttachDiskModal';
import { VirtualMachineDetail } from 'generated';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { byPrefixAndName } from '@awesome.me/kit-d2e55409b9/icons';
import AttachOpticalDiskModal from './AttachOpticalDiskModal';

interface AttachOpticalDiskButtonProps {
    virtualMachine: VirtualMachineDetail
}

const AttachOpticalDiskButton: React.FC<AttachOpticalDiskButtonProps> = ({ virtualMachine }) => {
    const { t } = useTranslation(['translation'])

    const [showModal, setShowModal] = useState(false);

    const { mutate } = useSWRConfig()

    const openModal = () => {
        mutate(`volumes/${virtualMachine.host.slug}`)
        setShowModal(true)
    }
    const [isBtntooltipOpen, setBtnTooltipOpen] = React.useState(false); 

    return (
        <React.Fragment>
            <AttachOpticalDiskModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false)
                }}
                virtualMachine={virtualMachine}
            />

            {virtualMachine.availableActions.modifyHardware ?(
                <Button color="soft-primary" className='ms-2' onClick={openModal}>
                    <FontAwesomeIcon icon={byPrefixAndName.fal["compact-disc"]} className='ms-1 me-2'/>
                    {t('translation:virtualMachine:buttons:opticalAttach')}
                </Button>
            ):(
                <React.Fragment>
                    <Button color="soft-secondary" id={`addOpticalDiskBtn`} className='mt-1 ms-2 btn-sm btn-block'>
                        <FontAwesomeIcon icon={byPrefixAndName.fal["compact-disc"]} className='ms-1 me-2'/>
                        {t('translation:virtualMachine:buttons:opticalAttach')}
                    </Button>
                    <Tooltip
                        isOpen={isBtntooltipOpen}
                        placement="left"
                        target={`addOpticalDiskBtn`}
                        toggle={() => { setBtnTooltipOpen(!isBtntooltipOpen) }}>
                        <div>{t('translation:common:warningActionUnavailable')}</div>
                    </Tooltip>
                </React.Fragment>
            )}


        </React.Fragment>
    )
};

export default AttachOpticalDiskButton;