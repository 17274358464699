import moment from 'moment';

// @ts-expect-error
const toMoment = (input: string): moment.Moment => {
    if (typeof input === 'string') {
        return moment.utc(input);
    } else {
        return moment.utc(input);
    }
};

const LocalTimeString = (input: string) => {
    return `${toMoment(input).local().format('h:mmA (UTCZ)')}`;
};

const LocalDataTimeString  = (input: string) => {
    return `${toMoment(input).local().format('MMM Do YYYY h:mmA (UTCZ)')}`;
};

const TimeAgoString  = (input: string) => {
    return `${toMoment(input).fromNow()}`;
};

export {LocalTimeString, LocalDataTimeString, TimeAgoString}